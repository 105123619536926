import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

export const validationApi = createApi({
  reducerPath: 'validationApi',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://api.dev.cash-u.com/anketa/api/',
    prepareHeaders(headers) {
      return headers;
    },
    credentials: "include"
  }),
  endpoints: (build) => ({
    checkUniquePhone: build.mutation<any, any>({
      query: (payload) => ({
        url: 'check-phone-unique/',
        method: 'POST',
        body: payload,
      }),
    }),
    
    checkUniqueEmail: build.mutation<any, any>({
      query: (payload) => ({
        url: 'check-email-unique/',
        method: 'POST',
        body: payload,
      }),
    }),

    sendSmsCodeSubmit: build.mutation<any, any>({
      query: (payload) => ({
        url: 'send-sms/',
        method: 'POST',
        body: payload,
      }),
    }),

    checkSmsCode: build.mutation<any, any>({
      query: (payload) => ({
        url: 'check-sms-code/',
        method: 'POST',
        body: payload,
      }),
    }),
    
    suggestInn: build.mutation<any, any>({
      query: (payload) => ({
        url: 'suggest-inn/',
        method: 'POST',
        body: payload,
      }),
    }),

    checkPhoneValidated: build.mutation<any, any>({
      query: (payload) => ({
        url: 'check-phone-validated/',
        method: 'POST',
        body: payload,
      }),
    }),

    checkAccount: build.mutation<any, any>({
      query: (payload) => ({
        url: 'account-check/',
        method: 'POST',
        body: payload,
      }),
    }),

    passCheck: build.mutation<any, any>({
      query: (payload) => ({
        url: 'passport-check/',
        method: 'POST',
        body: payload,
      }),
    }),

  }),
});
export const { useCheckUniquePhoneMutation } = validationApi;
export const { useCheckUniqueEmailMutation } = validationApi;
export const { useSendSmsCodeSubmitMutation } = validationApi;
export const { useCheckSmsCodeMutation } = validationApi;
export const { useSuggestInnMutation } = validationApi;
export const { useCheckPhoneValidatedMutation } = validationApi;
export const { useCheckAccountMutation } = validationApi;
export const { usePassCheckMutation } = validationApi;