import React from 'react';
import styles from './Information.module.scss';

export default function Information(props: { showBlock?: boolean }) {
  const { showBlock } = props;
  return (
    <div className={styles.content}>
      <div className={showBlock ? styles.header : styles.hide}>
        <img src="/img/person.svg" alt="Person" />
        <div className={styles.text}>
          Дорогие пользователи! Помните, что мы не используем платные подписки и не рассылаем спам.
          Мы помогаем своим клиентам!
        </div>
      </div>
      <div className={styles.info}>
        <img className={styles.infoIcon} src="/img/infoIcon.svg" alt="InfoIcon" />
        <div className={styles.text}>
          Мы используем ваши данные в целях индентификации и отправки договоров для получения
          денежных средств.
          <br />
          <br />
          Данные, введённые пользователями, находятся под надёжной защитой и не передаются третьим
          лицам.
        </div>
        <div className={styles.license}>Свидетельство ЦБ РФ № 1803392008777</div>
        <div className={styles.protect}>
          <img src="/img/guardIcon.svg" alt="Guard Icon" />
          <div className={styles.text}>
            Ваши данные защищены законодательством РФ и службой безопасности Cash-U Finance
          </div>
        </div>
      </div>
      <div className={showBlock ? styles.footer : styles.hide}>
        <div className={styles.title}>Уже зарегистрированы?</div>
        <div className={styles.link}>Перейдите на страницу входа в личный кабинет</div>
      </div>
    </div>
  );
}
