import React from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Information from '../Information/Information';
import styles from './Wrapper.module.scss';
import { Outlet, useLocation } from 'react-router-dom';

export default function Wrapper() {
  const location = useLocation();
  const stepOne = location.pathname.endsWith('/anketa/step/0');
  const acceptMobile = location.pathname.endsWith('/anketa/acceptmobile/');

  const showBlock = () => {
    if (stepOne || acceptMobile) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className={styles.wrapper}>
      <Header />
      <div className={styles.mainPos}>
        <Information showBlock={showBlock()} />
        <Outlet />
      </div>
      <Footer />
    </div>
  );
}
