import React from 'react';
import logo from '../logo.svg';
import styles from './Footer.module.scss';

export default function Footer(): JSX.Element {
  return (
    <div className={styles.footer}>
      <img src={logo} alt="Logo" />
      <div className={styles.aboutInfo}>
        © ООО микрокредитная компания «Киберлэндинг», 2018—2019. Организация
        действует на основании свидетельства о внесении в государственный реестр
        МФО №1803392008777 от 07.03.2018.
      </div>
    </div>
  );
}
