import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initApi } from '../../api/initApi';
// import { userApi } from '../../api/userApi';
interface SID {
    sid: string,
  }

const initialState: SID = {
    sid: '',
};

export const SidSlice = createSlice({
  name: 'SidSlice',
  initialState,
  reducers: {
    updateSid(state, { payload }: PayloadAction<SID>) {
      state.sid = payload.sid;
    },
    logout: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(initApi.endpoints.initUser.matchFulfilled, (state, { payload }: PayloadAction<{data: {sid: string}}>) => {

      state.sid = payload.data.sid;
    });
  },
});

export const userReducer = SidSlice.reducer;
export const { updateSid } = SidSlice.actions;