import React, { useState } from 'react';
import styles from './StepTwo.module.scss';
import { useNavigate } from 'react-router-dom';

interface Form {
  birthDate: string | Date;
  bornPlace: string;
  gender: string;
  passportSeries: string;
  passportNumber: string;
  passportCode: string;
  passportGetDate: string | Date;
  passportIssuedBy: string;
  addressRegister: string;
  addressFact: string;
}

export default function StepTwo() {
  const [showAddress, setShowAddress] = useState<boolean>(false);

  const navigate = useNavigate();
  return (
    <div className={styles.content}>
      <div className={styles.header}>
        <div className={styles.title}>
          <img src="/img/step-1-act.svg" alt="Step1" />
          <div>Основные данные</div>
        </div>
        <div className={styles.disable}>
          <img src="/img/step-2.svg" alt="Step2" />
          <img src="/img/step-3.svg" alt="Step3" />
        </div>
      </div>
      <div className={styles.progress}>
        <div className={styles.colored}>
          <div className={styles.text}>Вероятность одобрения: 55%</div>
        </div>
      </div>
      <form>
        <div className={styles.title}>Основные данные</div>
        <input className={styles.input} placeholder="Дата рождения*" />
        <input className={styles.input} placeholder="Место рождения по паспорту*" />
        <div className={styles.gender}>
          <label className={styles.genderTitle}>Пол*</label>
          <label className={styles.genderCheckbox}>
            <input type="checkbox" />
            Мужской
          </label>
          <label className={styles.genderCheckbox}>
            <input type="checkbox" />
            Женский
          </label>
        </div>
        <div className={styles.title}>Паспортные данные</div>
        <div className={styles.passport}>
          <input className={styles.inputPassport} placeholder="Серия*" />
          <input className={styles.inputPassport} placeholder="Номер*" />
        </div>
        <input className={styles.input} placeholder="Код подразделения*" />
        <input className={styles.input} placeholder="Дата выдачи*" />
        <input className={styles.input} placeholder="Кем выдан*" />
        <div className={styles.title}>Адрес регистрации</div>
        <input className={styles.input} placeholder="Регион, город, улица, дом, кв.*" />
        <div className={styles.showAddress}>
          <label className={styles.showAddressCheckbox}>
            <input
              type="checkbox"
              onClick={() => {
                setShowAddress(!showAddress);
              }}
            />
            Мой текущий адрес проживания отличается от адреса регистрации
          </label>
        </div>
        <input
          className={showAddress ? styles.input : styles.hide}
          placeholder="Регион, город, улица, дом, кв.*"
        />
        <div className={styles.btn}>
          <button id="nextStepBtn" type="submit">
            Далее
          </button>
          <div className={styles.btnBack} onClick={() => navigate('/anketa/step/0')}>
            <img src="/img/step-back-icon.svg" alt="Back" />
            Вернуться на предыдущий шаг, чтобы изменить данные
          </div>
        </div>
      </form>
    </div>
  );
}
