import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import ErrorPage from './pages/Error';
import StepTwo from './components/StepTwo';
import StepThree from './components/StepThree';
import StepFour from './components/StepFour';
import AcceptMobile from './components/AcceptMobile/AcceptMobile';
import Layout from './layout/Layout';
import Wrapper from './components/Wrapper/Wrapper';
import StepOne from './components/StepOne';

export const router = createBrowserRouter([
  {
    path: '/anketa',
    element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/anketa',
        element: <Wrapper />,
        errorElement: <ErrorPage />,
        children: [
          {
            path: 'step/0',
            element: <StepOne />,
          },
          {
            path: 'step/1',
            element: <StepTwo />,
          },
          {
            path: 'step/2',
            element: <StepThree />,
          },
          {
            path: 'step/3',
            element: <StepFour />,
          },
          {
            path: 'acceptmobile',
            element: <AcceptMobile />,
          },
          {
            path: 'acceptamount',
            element: <AcceptMobile />,
          },
        ],
      },
    ],
  },
]);
