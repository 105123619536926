import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initApi } from '../../api/initApi';

interface InitApiResponce {
    data: { form: { values: { 
    firstname: string;
    lastname: string;
    middlename: string;
    phone: string;
    email: string;
    locationborn: string;
    birthDate: string;
    seriapass: string;
    numberpass: string;
    codepass: string;
    dateget: string;
    locationget: string;
    locationlive: string;
    locationliveother: string;
    }}}
}

interface User {
    firstname?: string;
    lastname?: string;
    middlename?: string;
    phone?: string;
    email?: string;
    locationborn?: string;
    birthDate?: string;
    seriapass?: string;
    numberpass?: string;
    codepass?: string;
    dateget?: string;
    locationget?: string;
    locationlive?: string;
    locationliveother?: string;
  }

const initialState: User = {
    firstname: '',
    lastname: '',
    middlename: '',
    phone: '',
    email: '',
    locationborn: '',
    birthDate: '',
    seriapass: '',
    numberpass: '',
    codepass: '',
    dateget: '',
    locationget: '',
    locationlive: '',
    locationliveother: '',
};

export const userSlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {
    updateUser(state, { payload }: PayloadAction<User>) {
      state.firstname = payload.firstname;
      state.lastname = payload.lastname;
      state.middlename = payload.middlename;
      state.phone = payload.phone;
      state.email = payload.email;
      state.locationborn = payload.locationborn;
      state.birthDate = payload.birthDate;
      state.seriapass = payload.seriapass;
      state.numberpass = payload.numberpass;
      state.codepass = payload.codepass;
      state.dateget = payload.dateget;
      state.locationget = payload.locationget;
      state.locationlive = payload.locationlive;
      state.locationliveother = payload.locationliveother;
    },
    logout: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(initApi.endpoints.initUser.matchFulfilled, (state, { payload }: PayloadAction<InitApiResponce>) => {
        state.firstname = payload.data?.form.values?.firstname;
        state.lastname = payload.data?.form.values?.lastname;
        state.middlename = payload.data?.form.values?.middlename;
        state.phone = payload.data?.form.values?.phone;
        state.email = payload.data?.form.values?.email;
        //==step2==//
        state.locationborn = payload.data?.form.values?.locationborn;
        state.birthDate = payload.data?.form.values?.birthDate;
        state.seriapass = payload.data?.form.values?.seriapass;
        state.numberpass = payload.data?.form.values?.numberpass;
        state.codepass = payload.data?.form.values?.codepass;
        state.dateget = payload.data?.form.values?.dateget;
        state.locationget = payload.data?.form.values?.locationget;
        state.locationlive = payload.data?.form.values?.locationlive;
        state.locationliveother = payload.data?.form.values?.locationliveother;
    });
  },
});

export const userReducer = userSlice.reducer;
export const { updateUser } = userSlice.actions;