import React, { useState } from 'react';
import styles from './StepThree.module.scss';
import { useNavigate } from 'react-router-dom';

interface Form {
  inn: string | Date;
  snils: string;
  employment: string;
  nameCompany: string;
  positionAtWork: string;
  workPhone: string;
  monthlySalary: string | Date;
  addMonthlySalary: string;
  addressWork: string;
  fullNameThird: string;
  phoneThird: string;
}

export default function StepThree() {
  const [showAdditional, setShowAdditional] = useState<boolean>(false);

  const navigate = useNavigate();
  return (
    <div className={styles.content}>
      <div className={styles.header}>
        <div className={styles.stepGroup}>
          <div className={styles.disable}>
            <img src="/img/step-1.svg" alt="Step3" />
          </div>
          <div className={styles.title}>
            <img src="/img/step-2-act.svg" alt="Step1" />
            <div>Основные данные</div>
          </div>
        </div>
        <div className={styles.disable}>
          <img src="/img/step-3.svg" alt="Step3" />
        </div>
      </div>
      <div className={styles.progress}>
        <div className={styles.colored}>
          <div className={styles.text}>Вероятность одобрения: 70%</div>
        </div>
      </div>
      <form>
        <div className={styles.title}>Документы</div>
        <input className={styles.input} placeholder="ИНН*" />
        <input className={styles.input} placeholder="СНИЛС*" />
        <div className={styles.title}>Сфера деятельности</div>
        <input className={styles.input} placeholder="Занятость*" />
        <input className={styles.input} placeholder="Наименование организации*" />
        <input className={styles.input} placeholder="Название должности*" />
        <input className={styles.input} placeholder="Рабочий телефон*" />
        <input className={styles.input} placeholder="Зарплата в месяц*" />
        <div className={styles.showAdditional}>
          <label className={styles.showAdditionalCheckbox}>
            <input
              type="checkbox"
              onClick={() => {
                setShowAdditional(!showAdditional);
              }}
            />
            У меня есть дополнительный доход
          </label>
        </div>
        <input
          className={showAdditional ? styles.input : styles.hide}
          placeholder="Доп. доход в месяц*"
        />
        <div className={styles.title}>Адрес места работы</div>
        <input className={styles.input} placeholder="Регион, город, улица, дом, кв.*" />
        <div className={styles.title}>Контакты третьего лица</div>
        <div className={styles.thirdInfo}>
          <span>Третье лицо</span> – это человек, к которому в случае необходимости мы можем
          обратиться для подтверждения Вашей личности
        </div>
        <input className={styles.input} placeholder="ФИО третьего лица" />
        <input className={styles.input} placeholder="Номер телефона" />
        <div className={styles.btn}>
          <button id="nextStepBtn" type="submit">
            Далее
          </button>
          <div className={styles.btnBack} onClick={() => navigate('/anketa/step/1')}>
            <img src="/img/step-back-icon.svg" alt="Back" />
            Вернуться на предыдущий шаг, чтобы изменить данные
          </div>
        </div>
      </form>
    </div>
  );
}
