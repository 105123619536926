import React, { useState } from 'react';
import styles from './StepFour.module.scss';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';

export default function StepFour() {
  const [showInfo, setShowInfo] = useState<boolean>(false);
  const [promoButtonText, setPromoButtonText] = useState<string>('Применить');

  const navigate = useNavigate();

  return (
    <div className={styles.content}>
      <div className={styles.header}>
        <div className={styles.disable}>
          <img src="/img/step-1.svg" alt="Step1" />
          <img src="/img/step-2.svg" alt="Step2" />
        </div>
        <div className={styles.title}>
          <img src="/img/step-3-act.svg" alt="Step1" />
          <div>Параметры и опции займа</div>
        </div>
      </div>
      <div className={styles.progress}>
        <div className={styles.colored}>
          <div className={styles.text}>Вероятность одобрения: 80%</div>
        </div>
      </div>
      <form>
        <div className={styles.sliderMenu}>
          <div className={styles.title}>Желаемая сумма и срок займа</div>
          <div className={styles.sliderField}>
            <div className={styles.timeValue}>12 ДНЕЙ</div>
            <input className={styles.timeSlider} type="range" />
          </div>
          <div className={styles.sliderField}>
            <div className={styles.moneyValue}>15 000 РУБ</div>
            <input className={styles.moneySlider} type="range" />
          </div>
        </div>
        <div className={styles.delimiter}></div>
        <div className={styles.infoMenu}>
          <div className={styles.group}>
            <div className={styles.info}>
              <div className={styles.text}>Ставка:</div>
              <div className={styles.value}>1.00%</div>
            </div>
            <div className={styles.info}>
              <div className={styles.text}>Дата возврата:</div>
              <div className={styles.value}>
                <span>{format(new Date(new Date()), 'dd / MM / yyyy')}</span>
              </div>
            </div>
          </div>
          <div className={styles.info}>
            <div className={styles.text}>К возврату:</div>
            <div className={styles.value}>
              <span>16 800 РУБ</span>
            </div>
          </div>
        </div>
        <div
          className={styles.titleInfo}
          onClick={() => {
            setShowInfo(!showInfo);
          }}>
          Индивидуальные условия
        </div>
        <div className={styles.promocode}>
          <div className={styles.active}>
            <input placeholder="Промокод" />
            <button type="button">{promoButtonText}</button>
          </div>
          <div className={styles.status}>
            <div className={styles.success}>Промокод применен: снижение ставки на 20%</div>
            <div className={styles.error}>Срок действия промокода истек</div>
            <div className={styles.loading}>Обрабатывается</div>
          </div>
        </div>
        <div className={showInfo ? styles.additional : styles.hide}>
          <div className={styles.acceptAdditional}>
            <div className={styles.acceptCheckbox}>
              <label className={styles.titleAdditional}>
                <input className={styles.checkboxAdditional} type="checkbox" />
                Подключить Премиум-аккаунт
              </label>
            </div>
            <div className={styles.additionalText}>
              <span className={styles.cost}>25% от суммы займа, но не менее 500 РУБ</span>
              <br />
              Нажимая кнопку «Подключить премиум-аккаунт», вы соглашаетесь с&nbsp;
              <span className={styles.document}>Пользовательским соглашением</span>
            </div>
            <div className={styles.option}>
              <div className={styles.titleOption}>
                ВЫБИРАЙТЕ БЕЗГРАНИЧНЫЕ ВОЗМОЖНОСТИ ВМЕСТЕ С ПРЕМИУМ-АККАУНТОМ CASH-U
              </div>
              <div className={styles.optionValue}>
                <img src="/img/icon1Step3.svg" alt="Icon" />
                <div className={styles.optionText}>
                  Выделенный <span>личный менеджер</span> 24/7 для решения любого вопроса
                </div>
              </div>
              <div className={styles.optionValue}>
                <img src="/img/icon2Step3.svg" alt="Icon" />
                <div className={styles.optionText}>
                  <span>Гарантированное снижение ставки</span> на 20% на следующий заем
                </div>
              </div>
              <div className={styles.optionValue}>
                <img src="/img/icon3Step3.svg" alt="Icon" />
                <div className={styles.optionText}>
                  Актуальная <span>подборка промокодов</span> на разные товары от партнеров
                </div>
              </div>
              <div className={styles.optionValue}>
                <img src="/img/icon4Step3.svg" alt="Icon" />
                <div className={styles.optionText}>
                  Эксклюзивные <span>бонусы от СкайМани (Redmond)</span> на оплату до 50% стоимости
                  техники
                </div>
              </div>
            </div>
          </div>

          <div className={styles.acceptAdditional}>
            <div className={styles.acceptCheckbox}>
              <label className={styles.titleAdditional}>
                <input className={styles.checkboxAdditional} type="checkbox" />
                Подключить страховку жизни
              </label>
            </div>
            <div className={styles.additionalText}>
              <span className={styles.cost}>10% от суммы займа, но не менее 500 РУБ</span>
              <br />
              Нажимая кнопку «Подключить страховку жизни», вы соглашаетесь с&nbsp;
              <span className={styles.document}>
                Правилами страхования, коллективными договором и приказом NoT-01
              </span>
            </div>
            <div className={styles.option}>
              <div className={styles.optionValue}>
                <img src="/img/inf-step-3.svg" alt="Icon" />
                <div className={styles.optionText}>
                  Страховка дает гарантию на погашение вашего займ при наступлении страхового случая
                </div>
              </div>
            </div>
          </div>

          <div className={styles.agreement}>
            <div className={styles.agreementAccept}>
              <div className={styles.agreementTitle}>
                <input className={styles.checkboxAgreement} type="checkbox" />
                <label>
                  Согласие на уступку права (требования) и обязанности
                  <br />
                  по договору займа третьим лицам
                </label>
              </div>
              <div className={styles.agreementText}>
                Нажимая кнопку «Согласие на уступку права (требования) и обязанности по договору
                займа третьим лицам» Вы даете свое согласие Займодавцу на уступку полностью и/или
                частично права (требовния) и обязанности по Договору займа третьему лицу
              </div>
            </div>
            <div className={styles.agreementDelim}></div>
            <div className={styles.agreementAccept}>
              <div className={styles.agreementTitle}>
                <input className={styles.checkboxAgreement} type="checkbox" />
                <label>Согласие с условиями безакцептного платежа</label>
              </div>
              <div className={styles.agreementText}>
                Выражаю свое согласие оператору по переводу денежных средств (банку или иному
                оператору), который меня обслуживает, на перевод средств на основании требования
                получателя – кредитора, на условиях безакцептного платежа для целей погашения моей
                задолженности по договору займа
              </div>
            </div>
          </div>
        </div>
        <div className={showInfo ? styles.hide : styles.acceptPay}>
          <div className={styles.acceptTitle}>
            <input className={styles.checkboxPay} type="checkbox" />
            <label>Согласие с условиями безакцептного платежа</label>
          </div>
          <div className={styles.acceptText}>
            Выражаю свое согласие оператору по переводу денежных средств (банку или иному
            оператору), который меня обслуживает, на перевод средств на основании требования
            получателя – кредитора, на условиях безакцептного платежа для целей погашения моей
            задолженности по договору займа
          </div>
        </div>
        <div className={styles.titleInfo}>Подтверждение займа</div>
        <div className={styles.infoAccept}>
          Нажимите на кнопку «Получить деньги», чтобы подать&nbsp;
          <span>заявление на предоставление займа</span>
        </div>

        <div className={styles.btn}>
          <button id="nextStepBtn" type="submit">
            Получить деньги
          </button>
          <div className={styles.btnBack} onClick={() => navigate('/anketa/step/2')}>
            <img src="/img/step-back-icon.svg" alt="Back" />
            Вернуться на предыдущий шаг, чтобы изменить данные
          </div>
        </div>
      </form>
    </div>
  );
}
