import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useInitUserMutation } from '../api/initApi';
import { useTypedSelector } from '../redux/store';

export default function Layout() {
  const [initUser, { isLoading: isLoadingInitUser }] = useInitUserMutation();
  const sid = useTypedSelector((state) => state.SidSlice.sid);

  useEffect(() => {
    const init = async () => {
      let params = new URLSearchParams(window.location.search);
      let initialSum = params.get('initialSum') ? params.get('initialSum') : null;
      let initialDays = params.get('initialDays') ? params.get('initialDays') : null;
      params.delete('initialSum');
      params.delete('initialDays');
      try {
        const reqBody = {
          get_tags: {
            abLocalTest: '2',
            sid: sid ? sid : null,
            initialSum: initialSum,
            initialDays: initialDays,
            params: Object.fromEntries(params),
          },
        };
        await initUser(reqBody).unwrap();
      } catch (err: any) {
        console.log(err);
      }
    };
    init();
  }, []);

  return (
    <>
      <Outlet />
    </>
  );
}
