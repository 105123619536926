import React from 'react';
import logo from '../logo.svg';
import styles from './Header.module.scss';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Header = (): JSX.Element => {
  const [t, i18n] = useTranslation();
  const navigate = useNavigate();
  return (
    <div className={styles.header}>
      <img
        className={styles.logo}
        src={logo}
        alt="Logo"
        // onClick={() => navigate('https://cash-u.com/')}
      />
      <div className={styles.aboutInfo}>
        <div className={styles.info}>Бесплатно и круглосуточно</div>
        <div className={styles.number}>8 800 505-07-75</div>
      </div>
      <div className={styles.login}>
        <img
          className={styles.enterIcon}
          width={18}
          height={18}
          src="/img/enter-icon.svg"
          alt="EnterIcon"
        />
      </div>
    </div>
  );
};

export default Header;