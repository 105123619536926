import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { userSlice } from './Slices/phoneSlice';
import { SidSlice } from './Slices/initSlice';
import persistReducer from 'redux-persist/es/persistReducer';

import { initApi } from '../api/initApi';
import { validationApi } from '../api/validationApi';
import { saveApi } from '../api/saveApi';
import { TypedUseSelectorHook, useSelector } from 'react-redux';

const userPersistConfig = {
  key: 'user',
  storage,
};

const sIDPersistConfig = {
  key: 'sID',
  storage,
};

const rootReducer = combineReducers({
  [userSlice.name]: persistReducer(userPersistConfig, userSlice.reducer),
  // userSlice: userSlice.reducer,
  [SidSlice.name]: persistReducer(sIDPersistConfig, SidSlice.reducer),

  [initApi.reducerPath]: initApi.reducer,
  [validationApi.reducerPath]: validationApi.reducer,
  [saveApi.reducerPath]: saveApi.reducer,
});

export const store = configureStore({
  reducer: rootReducer,

  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(initApi.middleware, validationApi.middleware, saveApi.middleware),
});

export const persistor = persistStore(store);
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
