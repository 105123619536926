import React, { useEffect, useState } from 'react';
import styles from './StepOne.module.scss';
import { Controller, useForm } from 'react-hook-form';
import {
  useCheckPhoneValidatedMutation,
  useCheckUniqueEmailMutation,
  useCheckUniquePhoneMutation,
} from '../../api/validationApi';
import { useSaveDataMutation } from '../../api/saveApi';
import { useSendSmsMutation } from '../../api/sendSmsApi';
import { useNavigate } from 'react-router-dom';

interface Form {
  lastname: string;
  firstname: string;
  middlename: string;
  phone: string;
  email: string;
  checkAll: boolean;
  agreementСonditions: boolean;
  personalData: boolean;
  agreementASP: boolean;
  agreementSale: boolean;
  creditHistory: boolean;
}

export default function StepOne() {
  const onlyRussian = /^[\u0400-\u04FFёЁ](?:[\u0400-\u04FFёЁ\s-]*)$/;
  const onlyEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  const [checkPhone] = useCheckUniquePhoneMutation();
  const [checkEmail] = useCheckUniqueEmailMutation();
  const [checkPhoneValidation] = useCheckPhoneValidatedMutation();
  const [saveData] = useSaveDataMutation();
  const [sendSms] = useSendSmsMutation();

  const nagivate = useNavigate();

  const rulesForTextInput = {
    required: true,
    minLength: 2,
    maxLength: 150,
    pattern: onlyRussian,
  };

  //for checkbox
  const [checkedAll, setCheckedAll] = useState(false);
  const [checked, setChecked] = useState<{ [key: string]: boolean }>({
    nr1: false,
    nr2: false,
    nr3: false,
    nr4: false,
    nr5: false,
  });

  const toggleCheck = (inputName: any) => {
    setChecked((prevState) => {
      const newState = { ...prevState };
      newState[inputName] = !prevState[inputName];
      return newState;
    });
  };

  const selectAll = (value: any) => {
    setCheckedAll(value);
    setChecked((prevState) => {
      const newState = { ...prevState };
      for (const inputName in newState) {
        newState[inputName] = value;
      }
      return newState;
    });
  };

  useEffect(() => {
    let allChecked = true;
    for (const inputName in checked) {
      if (checked[inputName] === false) {
        allChecked = false;
      }
    }
    if (allChecked) {
      setCheckedAll(true);
    } else {
      setCheckedAll(false);
    }
  }, [checked]);
  //end for checkbox

  const {
    getValues,
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<Form>({
    mode: 'onChange',
    criteriaMode: 'all',
    defaultValues: {
      firstname: '',
      lastname: '',
      middlename: '',
      phone: '',
      email: '',
      checkAll: false,
      agreementСonditions: false,
      personalData: false,
      agreementASP: false,
      agreementSale: false,
      creditHistory: false,
    },
  });

  const handSubmit = (data: any) => {
    console.log(data);
    handleCheckPhone();
  };

  const handleCheckPhone = async () => {
    try {
      const reqBody = {
        phone: getValues('phone'),
      };
      await checkPhone(reqBody)
        .unwrap()
        .then((response) => {
          if (response.data.number === 'inKraud') {
            console.log('Номер уже в базе данных, показать попап');
          }
          if (response.data === true) {
            console.log('Номер не в базе данных, идем дальше');
            handleCheckEmail();
          }
        });
    } catch (err: any) {
      console.log(err);
    }
  };

  const handleCheckEmail = async () => {
    try {
      await checkEmail(getValues('email'))
        .unwrap()
        .then((response) =>
          response.data ? handleCheckPhoneValid() : console.log('error-check-email'),
        );
    } catch (err: any) {
      console.log(err);
    }
  };

  const handleCheckPhoneValid = async () => {
    try {
      const reqBody = {
        phone: getValues('phone'),
      };
      await checkPhoneValidation(reqBody)
        .unwrap()
        .then((response) => {
          response.data ? nagivate('/anketa/step/1') : handleSaveData();
        });
    } catch (err: any) {
      console.log(err);
    }
  };

  const handleSaveData = async () => {
    try {
      const reqBody = {
        values: {
          firstname: getValues('firstname'),
          lastname: getValues('lastname'),
          middlename: getValues('middlename'),
          phone: getValues('phone'),
          email: getValues('email'),
        },
      };
      await saveData(reqBody)
        .unwrap()
        .then((response) => {
          response.data === true ? handleSendSms() : console.log('error-save-data');
        });
    } catch (err: any) {
      console.log(err);
    }
  };

  const handleSendSms = async () => {
    try {
      const reqBody = {
        phone: getValues('phone'),
      };
      await sendSms(reqBody)
        .unwrap()
        .then((response) => {
          response.data ? nagivate('/anketa/acceptmobile') : console.log('error-send-sms');
        });
    } catch (err: any) {
      console.log(err);
    }
  };

  return (
    <div className={styles.content}>
      <div className={styles.header}>
        <div className={styles.title}>
          Оформите заявку
          <br /> и получите до <span>30 000</span> руб.
        </div>
      </div>
      <div className={styles.progress}>
        <div className={styles.progressFilled}>Регистрация для получения первого займа</div>
      </div>
      <form onSubmit={handleSubmit(handSubmit)}>
        <Controller
          name="lastname"
          control={control}
          rules={rulesForTextInput}
          render={({ field }) => <input id="lastname" placeholder="Фамилия*" {...field} />}
        />
        <Controller
          name="firstname"
          control={control}
          rules={rulesForTextInput}
          render={({ field }) => <input id="firstname" placeholder="Имя*" {...field} />}
        />
        <Controller
          name="middlename"
          control={control}
          rules={rulesForTextInput}
          render={({ field }) => <input id="middlename" placeholder="Отчество*" {...field} />}
        />
        <input
          id="mobilePhone"
          placeholder="Мобильный телефон*"
          {...register('phone', { required: true })}
        />
        <Controller
          name="email"
          control={control}
          rules={{ required: true, pattern: onlyEmail }}
          render={({ field }) => <input id="email" placeholder="Электронная почта*" {...field} />}
        />
        <div className={styles.checkboxField}>
          <div className={styles.active}>
            <label className={styles.checkboxContainer}>
              <input
                className={styles.hiddenCheckbox}
                type="checkbox"
                onChange={(event) => selectAll(event.target.checked)}
                checked={checkedAll}
              />
              <span
                id="checkAll"
                className={`${styles.customCheckbox} ${checkedAll ? styles.checked : ''}`}
              />
              <label
                className={`${styles.customCheckboxLabel} ${
                  checkedAll ? styles.activeCheckboxLabel : ''
                }`}>
                Выбрать всё
              </label>
            </label>
          </div>

          <div className={styles.soloCheckbox}>
            <div className={styles.active}>
              <label className={styles.checkboxContainer}>
                <input
                  className={styles.hiddenCheckbox}
                  type="checkbox"
                  name="nr1"
                  onChange={() => toggleCheck('nr1')}
                  checked={checked['nr1']}
                />
                <span
                  id="checkNr1"
                  className={`${styles.customCheckbox} ${checked['nr1'] ? styles.checked : ''}`}
                />
                <label
                  className={`${styles.customCheckboxLabel} ${
                    checked['nr1'] ? styles.activeCheckboxLabel : ''
                  }`}>
                  Я ознакомился с Общими условиями договора займа
                </label>
              </label>
            </div>

            <div className={styles.active}>
              <label className={styles.checkboxContainer}>
                <input
                  className={styles.hiddenCheckbox}
                  type="checkbox"
                  name="nr2"
                  onChange={() => toggleCheck('nr2')}
                  checked={checked['nr2']}
                />
                <span
                  id="checkNr2"
                  className={`${styles.customCheckbox} ${checked['nr2'] ? styles.checked : ''}`}
                />
                <label
                  className={`${styles.customCheckboxLabel} ${
                    checked['nr2'] ? styles.activeCheckboxLabel : ''
                  }`}>
                  Я даю согласие на обработку персональных данных
                </label>
              </label>
            </div>

            <div className={styles.active}>
              <label className={styles.checkboxContainer}>
                <input
                  className={styles.hiddenCheckbox}
                  type="checkbox"
                  name="nr3"
                  onChange={() => toggleCheck('nr3')}
                  checked={checked['nr3']}
                />
                <span
                  id="checkNr3"
                  className={`${styles.customCheckbox} ${checked['nr3'] ? styles.checked : ''}`}
                />
                <label
                  className={`${styles.customCheckboxLabel} ${
                    checked['nr3'] ? styles.activeCheckboxLabel : ''
                  }`}>
                  Я принимаю Соглашение об использовании АСП
                </label>
              </label>
            </div>

            <div className={styles.active}>
              <label className={styles.checkboxContainer}>
                <input
                  className={styles.hiddenCheckbox}
                  type="checkbox"
                  name="nr4"
                  onChange={() => toggleCheck('nr4')}
                  checked={checked['nr4']}
                />
                <span
                  id="checkNr4"
                  className={`${styles.customCheckbox} ${checked['nr4'] ? styles.checked : ''}`}
                />
                <label
                  className={`${styles.customCheckboxLabel} ${
                    checked['nr4'] ? styles.activeCheckboxLabel : ''
                  }`}>
                  Я согласен на информирование об акциях и продуктах
                </label>
              </label>
            </div>

            <div className={styles.active}>
              <label className={styles.checkboxContainer}>
                <input
                  className={styles.hiddenCheckbox}
                  type="checkbox"
                  name="nr5"
                  onChange={() => toggleCheck('nr5')}
                  checked={checked['nr5']}
                />
                <span
                  id="checkNr5"
                  className={`${styles.customCheckbox} ${checked['nr5'] ? styles.checked : ''}`}
                />
                <label
                  className={`${styles.customCheckboxLabel} ${
                    checked['nr5'] ? styles.activeCheckboxLabel : ''
                  }`}>
                  Я ознакомился с Согласием субъекта кредитной истории
                </label>
              </label>
            </div>
          </div>
        </div>

        <div className={styles.btn}>
          <button id="nextStepBtn" type="submit">
            Далее
          </button>
        </div>
      </form>
    </div>
  );
}
