import React, { useEffect, useState } from 'react';
import styles from './AcceptMobile.module.scss';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useTypedSelector } from '../../redux/store';
import { useCheckSmsCodeMutation, useCheckPhoneValidatedMutation } from '../../api/validationApi';
import { useNavigate } from 'react-router-dom';
import InputMask from 'react-input-mask';
interface FormValues {
  smsCode: string;
}

export default function AcceptMobile() {
  const [statusCode, setStatusCode] = useState(0);
  const [checkSmsCode, { isLoading }] = useCheckSmsCodeMutation();
  const [checkPhoneValid] = useCheckPhoneValidatedMutation();
  const [inputValue, setInputValue] = useState('');
  const [counter, setCounter] = useState<number>(60);
  const userPhone = useTypedSelector((state) => state.userSlice.phone);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    getValues,
    control,
    formState: { errors, isValid, isDirty },
  } = useForm<FormValues>({
    defaultValues: {
      smsCode: '',
    },
  });

  const SubmitForm = async () => {
    try {
      const reqBody = {
        smsCode: inputValue,
        phone: userPhone,
      };
      await checkSmsCode(reqBody)
        .unwrap()
        .then((response) =>
          response.data ? navigate('/anketa/step/1') : console.log('error-code'),
        );
    } catch (err: any) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (inputValue.length === 6 && inputValue.indexOf('_') === -1) {
      SubmitForm();
    }
  }, [inputValue]);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    if (counter === 0) {
    } else if (counter > 0) {
      timer = setInterval(() => setCounter(counter - 1), 1000);
    }
    return () => {
      timer && clearInterval(timer);
    };
  }, [counter]);

  return (
    <div className={styles.content}>
      <div className={styles.contentHeader}>
        <h1>Подтверждение телефона</h1>
      </div>
      <div className={styles.contentMain}>
        <h2>
          На ваш номер <span>{userPhone}</span> отправлено смс с кодом подтверждения
        </h2>
        <div className={styles.codeField}>
          <div className={styles.activeField}>
            <h3>Введите полученный смс-код в поле:</h3>
            <form onSubmit={handleSubmit(SubmitForm)}>
              <InputMask
                mask="999999"
                value={inputValue}
                placeholder="- - - - - -"
                onChange={(event: { target: { value: React.SetStateAction<string> } }) =>
                  setInputValue(event.target.value)
                }
              />
            </form>
            <ul>
              <li className={statusCode === 1 ? styles.statusLoad : styles.hidden}>
                <img src="/img/loadPhoneIcon.svg" alt="LoadIcon" />
                Смс-код обрабатывается
              </li>
              <li className={statusCode === 2 ? styles.statusOk : styles.hidden}>
                <img src="/img/okeyPhoneIcon.svg" alt="OkeyIcon" />
                Смс-код подтвержден. Через несколько секундвы будете переброшены на следующий шаг
              </li>
              <li className={statusCode === 3 ? styles.statusError : styles.hidden}>
                Произошла ошибка. Обратитесь в службу поддержки по телефону 8 800 505-07-75
              </li>
              <li className={statusCode === 4 ? styles.statusError : styles.hidden}>
                Введен неверный смс-код. Проверьте правильность кода и введите его еще раз
              </li>
            </ul>
            <p>
              Если смс-код не пришел, повторно можно будет отправить через <span>{counter}</span>{' '}
              секунд
            </p>
          </div>
          <img className={styles.phoneIcon} src="/img/phoneIcon.svg" alt="Phone Icon" />
        </div>
        <div className={styles.footerField}>
          <Link to="/">
            <span>Вернуться, чтобы изменить данные</span>
          </Link>
        </div>
      </div>
    </div>
  );
}
