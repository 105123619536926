import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

export const initApi = createApi({
  reducerPath: 'initApi',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://api.dev.cash-u.com/anketa/api/',
    prepareHeaders(headers) {
      return headers;
    },
    credentials: "include"
  }),
  endpoints: (build) => ({
    initUser: build.mutation<any, any>({
      query: (payload) => ({
        url: 'init/',
        method: 'POST',
        body: payload,
      }),
    }),
  }),
});
export const { useInitUserMutation } = initApi;
